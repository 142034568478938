.slide-container {
    width: 70%;
    margin: auto; 

    .nav {
      background: $color_main;
      border-color: white;
      border-radius: 0;
      opacity: .5;

      &:hover {
        background: $color_main;
        opacity: 1;
      }
    }
}
.react-slideshow-container + div.indicators > div:hover,
.react-slideshow-container + div.indicators > div.active {
    background: $color_main;
}
  
  h3 {
    text-align: center; }
  
  .each-slide > div {
    cursor: pointer;
    display: flex;
    margin: 0;
    align-items: flex-end;
    justify-content: flex-end;
 
    height: 600px;
  }
  
  .each-slide span {
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    background: $color_main;
    text-align: center;
  }
  
  .each-fade {
    display: flex;
  }
  
  .each-fade .image-container {
    width: 75%;
    overflow: hidden;
  }
  
  .each-fade .image-container img {
    width: 100%;
  }
  
  .each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }