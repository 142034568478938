.d-flex {
    @include flexbox();
    flex-wrap: wrap;
}
.justify-content-center {
    justify-content: center;
}
.h-100 {
    min-height: 100%;
}

.h-100vh {
    min-height: 100vh !important;
}

.w-100 {
    width: 100%;
}
.cursor-pointer {
    cursor: pointer;
}
/* 
//////////////////////////////////////////////////////////////
Margin / Padding 
*/
.mx-auto {
    margin: 0 auto;
}

.m-0 {
    margin: 0 !important;
}

.m-5 {
    margin: 25px;
}
.mt-0 {
    margin-top: 0;
}
.mt-5 {
    margin-top: 25px;
}
.mr-5 {
    margin-right: 25px;
}
.ml-5 {
    margin-left: 25px;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-5 {
    margin-bottom: 25px;
}
.p-0 {
    padding: 0 !important;
}
.p-100 {
    padding: 100px !important;
}
.p-5 {
    padding: 25px;
}
.pt-0 {
    padding-top: 0;
}
.pt-5 {
    padding-top: 25px;
}
.pl-5 {
    padding-left: 25px;
}
.pb-5 {
    padding-bottom: 25px;
}

