/*
//////////////////////////////////////////////////////
Responsive
*/
@media #{$medium-and-down} {
    .p-100 {
      padding: 20px !important;
    }
    .navbar-fixed {
      height: 70px;
      min-height: 70px;
    }
    nav.nav_main {
      background: white;
      @include box-shadow(none);
      height: 70px;
      min-height: 70px;

      .brand-logo img {
        margin: 5px 20px;
        width: 50px;
        height: 63px;
      }
    } 
    #app_presentation {
      padding: 0;

      h1 {
        font-size: 1.5rem;
      }
      h2 {
        font-size: 5rem !important;
        line-height: 5rem !important;
      }
      .card {
        margin: 50px 0 !important;
        max-width: none !important;
      }
      & > .col {
        padding: 0;
      }
      .btn {
        @include justify-content(center);
        margin-top: 20px;

        span {
          margin-left: 0 !important;
        }
      }
      .app_presentation-tag {
        min-height: auto;
      }
      .app_presentation-participez {
        background: white;
      }
    
    }
    .post_list-summary {
      .card-image img {
          width: 100%;
          left: auto;
          min-width: auto;
          min-height: auto;
      }
    }
    #app_lots {
        h3 {
          display: block !important;
          font-weight: bold;
          font-size: 20px;
        }
        #tab_20, #tab_21, #tab_22, #tab_23 {
          display: block !important;
        }
        .btn {
          width: 90%;
          max-width: none;
          @include flexbox();
          @include justify-content(center);

          i, span {
            width: auto;
          }
          span {
            margin-right: 1rem;
          }
        }
        .tab_image {
          background-size: cover;
          width: 100%;
          height: 500px;
        }
    }
    #app_notice {
      background: white;
    }
    #app_participez {
      background: transparent !important;
      padding-top: 25px;

      h3 {
        padding-bottom: 20px;
      }

      .app_participez-content-text {
        h2 {
          font-size: 2.2rem;
        }
        color: white !important;
      }
      .app_participez-content-form,
      .app_participez-content-form-footer {
        margin: auto 15px !important;
      }
      .app_participez-content-form *:not(.btn) {
        color: black !important;
      }
      .filepicker {
        width: 100%;
      }

      & > .row  > .col {
        padding: 0 25px;
      }
      .input-field:nth-child(odd) {
        border-right: 0 !important;
      }
    }
    .modal {
        .modal-content {
          padding: 12px;
        }
        .media_video {
          width: 100% !important;
        }
    }
    #app_participations h2.title,
    #app_gagnants  h2.title {
        font-size: 100px !important;
        line-height: 95px !important;
    }
    .slide-container {
      width: 100%;

      .react-slideshow-container {
        align-items: flex-end;
      }
      .each-slide {
        div {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          justify-content: center;
          height: 280px;
        }
        span {
          padding: 0 15px;
          font-size: 12px;
        }
      }
    }
}
@media screen and (max-width: 1600px) {
    nav.nav_main {
      .nav-wrapper > ul {
        margin-left: 100px;
        @include calc(width, '100% - 100px', '');
      }
      li.right {
        margin-left: 0 !important;
        height: $menu_height;
        @include flexbox();
        @include align-items(center);

        &:after {
          display: none;
        }
        ul {
          line-height: 2rem;
          margin: 0;
          text-align: right;
        }
        li {
          float: none; 
        }
      }
      li {
        margin-left: 0 !important;
        font-size: 1rem !important;

        &:after {
          left: -44px !important;
        }
      }      
    }
}
@media screen and (max-width: 1140px) {
  #app_presentation > .row {
    padding-right: 0 !important;
  }
  #app_lots {
    display: block;

    ul.tabs {
      display: none !important;
    }
  
    .row {
      display: block !important;
      padding: 0 25px;
    }
    .tab-container {
      @include flex-direction(column-reverse);
      padding: 0 25px;
    }
    .tab.col {
      display: block !important;
      clear: none !important;
      width: auto !important;
      float: left !important;
    }
   
    ul.tabs {
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
      background: transparent;
    }
    .tabs li {
      line-height: inherit;
      text-align: center;
    }
    .tab_content,
    .col {
      clear: both;
      display: block !important;
      float: none !important;
      padding: 0;
      margin: 0;
      width: 100% !important;
    }
    .tab_image {
      float: none;
      margin: 25px auto 0 auto;
      width: 100%;
    }
    .btn {
      position: relative;
      bottom: auto;
      left: auto;
      margin: 20px auto;
    }
  }
}