$color_main: #ff2a00;
$red: #ff2a00;
$button-disabled-background: transparent;

.bg_main {
    background-color: $color_main !important;
} 
.color_main {
    color: $color_main !important;
}
$gray: #ccc;
$menu_height: 98px;
$footer_height: 80px;

@font-face {
    font-family: 'segoeui';
    src: url('../../fonts/SegoeUI.eot');
    src: url('../../fonts/SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/SegoeUI.woff2') format('woff2'),
        url('../../fonts/SegoeUI.woff') format('woff'),
        url('../../fonts/SegoeUI.ttf') format('truetype'),
        url('../../fonts/SegoeUI.svg#SegoeUI') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'segoeuiBold';
    src: url('../../fonts/SegoeUI-Bold.eot');
    src: url('../../fonts/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/SegoeUI-Bold.woff2') format('woff2'),
        url('../../fonts/SegoeUI-Bold.woff') format('woff'),
        url('../../fonts/SegoeUI-Bold.ttf') format('truetype'),
        url('../../fonts/SegoeUI-Bold.svg#SegoeUI') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'segoeuiBlack';
    src: url('../../fonts/SegoeUIBlack.eot');
    src: url('../../fonts/SegoeUIBlack.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/SegoeUIBlack.woff2') format('woff2'),
        url('../../fonts/SegoeUIBlack.woff') format('woff'),
        url('../../fonts/SegoeUIBlack.ttf') format('truetype'),
        url('../../fonts/SegoeUIBlack.svg#SegoeUIBlack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DCC-SharpDistress';
    src: url('../../fonts/DCC-SharpDistressBlack.eot');
    src: url('../../fonts/DCC-SharpDistressBlack.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/DCC-SharpDistressBlack.woff2') format('woff2'),
        url('../../fonts/DCC-SharpDistressBlack.woff') format('woff'),
        url('../../fonts/DCC-SharpDistressBlack.ttf') format('truetype'),
        url('../../fonts/DCC-SharpDistressBlack.svg#DCC-SharpDistressBlack') format('svg');
    font-weight: 900;
    font-style: normal;
}