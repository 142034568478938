// @include calc(width, '100% - 105px');
@mixin calc($property, $expression, $option) {
	#{$property}: -moz-calc(#{$expression}) #{$option};
	#{$property}: -webkit-calc(#{$expression}) #{$option};
	#{$property}: calc(#{$expression}) #{$option};
}
@mixin box-shadow($params) {
    -webkit-box-shadow: $params;
    -moz-box-shadow: $params;
    box-shadow: $params;
}

@mixin flexbox {
    display:-webkit-box; // old
    display:-moz-box; // old
    display:-ms-flexbox; // ie
    display:-webkit-flex; // new
    display:flex; // new
}
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
    -ms-flex-pack: $justify;
}
// flex-start | flex-end | center | baseline | stretch 
@mixin align-items($align) {
    -webkit-align-items: $align;
       -moz-align-items: $align;
        -ms-align-items: $align;
            align-items: $align;
        -ms-flex-pack: $align;

}
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
       -moz-flex-direction: $direction;
        -ms-flex-direction: $direction;
            flex-direction: $direction;
  }
.align-items-center {
    @include align-items(center);
}
.align-items-start {
    @include align-items(flex-start);
}
.justify-content-between {
    @include justify-content(space-between);
}
.flex-direction-column {
    @include flex-direction(column);
}