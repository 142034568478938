
/*
//////////////////////////////////////////////////////////////
Participation form
*/
.form {
    padding: 1rem 2rem;

  label {
    left: 0 !important;
    &.active {
      color : rgb(182, 182, 182) !important;
      font-size: 10px !important;
    }
    color: black;
  }
  
  input,
  label,
  textarea {
    padding: 12px !important; 
  }

  &[type="checkbox"]+label:not(.active):before {
    border: solid 2px black;
  }
  &[type="checkbox"]:checked+label:before {
    border-right: solid 2px white;
    border-bottom: solid 2px white;
  }
  input[type=text]:not(.browser-default):focus:not([readonly]),
  input[type=email]:not(.browser-default):focus:not([readonly]),
  input[type=password]:not(.browser-default):focus:not([readonly]),
  textarea.materialize-textarea:focus:not([readonly]) {
    border-color: black;
    box-shadow: none;
    color: #ccc;
  }
  
  button, 
  h5 {
    margin: 20px 0;
  }
}

[type="checkbox"] + span:not(.lever):before, [type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  border: solid 1px black;
}

/*
//////////////////////////////////////////////////////////////
BTN
*/
.btn {
    background-color: black;
    @include box-shadow(none !important);
    font-size: 16px;
    letter-spacing: .3rem;
    overflow: visible;
    color: white;
    margin: 0;
    text-transform: uppercase;
  
    &.btn-transparent, 
    &.btn-transparent:active, 
    &.btn-transparent:visited {
      background: transparent;
      box-shadow: none !important;
      color: black;
      padding: 0;
    }
    &.btn-transparent:hover {
      background: transparent;
      color: #ccc;
    }
}
.waves-effect .waves-ripple {
  background-color: $color_main;
}
button.disabled {
  opacity: .5;
}
