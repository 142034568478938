/*
Présentation
//////////////////////////////////////////////////////////////
*/

#app_presentation {
    
    & > div.row {
        @include flexbox();
        width: 100%;
        padding-right: 50px;
    }
    h1 {
        margin-bottom: 20px;
    }
    
    .app_presentation-tag {
        background-image: url(../../img/hand.png);
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: contain;
        min-height: 100vh;

        p {
            color: black;
            display: inline-block;
            font-family: "segoeuiBlack", Arial, sans-serif;
            font-size: 12px;
            letter-spacing: .2rem;
            text-transform: uppercase;
            margin-left: 15px;
            border-bottom: solid 5px $color_main;
            border-top: solid 5px $color_main;
            padding: 15px ;

            .bigger {
                font-size: 16px;
            }
        }
    }
    .chapo {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 30px;

        b {
            font-weight: normal;
            color: $color_main;
        }
    }
    .card {
        background-color: transparent !important;
        box-shadow: none;
        max-width: 379px;
        overflow: visible;

        .btn {
            padding: 0 0rem 0 1rem;
            margin-top: 20px;

            span {
                display: block;
                margin-left: 2rem;
                margin-right: 1rem;
                text-align: left;
            }
            i {
                color: white !important;
            }
        }
    }
} 

/*
Lots
//////////////////////////////////////////////////////////////
*/
#app_lots {
    @include flexbox();
    position: relative;

    & > .row:first-child {
        width: 100%;
        margin: 0;
    }
    & > .row:first-child,
    .tab-container {
        @include flexbox();
        @include align-items(center);
    }
    .tabs {
        padding: 3rem 2rem;
    }
    .tabs li {
        display: block;
        font-family: 'segoeuiBold', Arial;
        float: none;
        text-align: left;
        height: auto;
        line-height: 4.5rem;

        a {
            display: inline;
            padding: 10px 50px 10px 0px;
            font-size: 13px;
            letter-spacing: .15rem;
            position: relative;
            width: auto;

            &:focus {
                background: transparent;
            }
            &:hover,
            &.active {
                color: $color_main !important;

                &:after {
                    bottom: 0;
                    left: 0;
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background: $color_main;
                }
            }
        }
    }
    h3 {
        display: none;
    }
    .indicator {
        display: none;
    }
    & > .row {
        & > .col {
            float: left;
            padding: 0;
            width: 85%;
        }
        & > .col:first-child {
            width: 15%;
        }
    }
    & > .col {
        float: left;
        width: 60%;
    }
    .tab_content {
        float: left;
        padding: 0 80px;
        width: 40%;
        margin-left: 2rem;
        margin-top: 0;
        left: auto;
        right: auto;


        p {
            margin: 0; 
        }
        p:first-child {
            font-size: 24px;
            line-height: 30px;
            color: $color_main;
            padding-bottom: 24px;
        }
        
    }
    .tab_chapo {
        font-weight: bold;
    }
    .tab_image {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(flex-end);
        float: left;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-left: auto;
        left: auto;
        right: auto;
        width: 60%;
        height: calc(100vh - 128px);
        position: relative;

        span {
            position: absolute;
            bottom: 20px;
            right: 20px;
            color: white;
        }
    }
    .btn {
        height: auto;
        line-height: 40px;
        max-width: 200px;
        margin-top: 25px;
        padding: 10px 0;

        span, i {
            margin: 0;
            width: 100%;
        }
    }
}
/*
Notice
//////////////////////////////////////////////////////////////
*/
#app_notice {
    text-align: center;
}
/*
Participez
//////////////////////////////////////////////////////////////
*/
#app_participez {
    min-height: auto !important;

    .app_participez-content {
        color: white !important;

        h2 {
            color: white;
            font-size: 3.6rem;
            font-weight: 300;
            text-transform: none;
        }
        .app_participez-content-text {
            padding: 0 10vw;
        }
    }
    h3 {
        font-size: 2rem;
    }
    .btn {
        background: transparent;
        box-shadow: none;
        color: black;
        font-weight: bold;
        height: auto;
    }
    .btn-floating {
        position: relative;

        &:after {
            content: '';
            background-image: url(../../img/icons/tick.svg);
            width: 20px;
            height: 20px;
            position: absolute;
            @include calc(top, '50% - 10px', '');
            @include calc(left, '50% - 10px', '');
        }
    }
    .app_participez-content-form {
        border: solid 1px black;
        letter-spacing: .1rem;
        margin: 1.5rem 5rem 0 5rem;
        padding: 0 0;

        & > .col:not(:last-child) {
            margin: 0;
            padding: 0;
            border-bottom: solid 1px black;
        }
        .no_borderb {
            border-bottom: 0 !important;

            label {
                display: block;
                margin: .4rem 0;
                line-height: .9rem;

                span {font-size: 11px;}
            }
        }
        .input-field:last-child label.active {
            top: -20px;
        }
        .input-field:nth-child(odd):not(:last-child) { 
            border-right: solid 1px black;
        }
        
        label,
        input,
        textarea {
            border-bottom: 0;
            font-size: 13px;
            margin: 0;
        }
        input,
        label {
            padding: 6px !important;
        }
        textarea { 
            min-height: 3rem; 
            padding: 22px 6px !important;
        }
        
        label {
            text-transform: uppercase;

            &.active {
                transform: none;
            }
        }        
    }
    .app_participez-content-form-footer {
        margin: 0 5rem 0 5rem;
    }
    .isgardien p {
        margin-top: 5px;
    }
    .isgardien span {
        text-transform: none !important;
        letter-spacing: normal !important;
    }
    .isgardien label {
        padding: 0 !important;
    }
    .required {
        text-align: right;
        font-size: 11px;
        margin-bottom: 0;
    }
    .btn.tooltipped {
        letter-spacing: inherit;
        font-size: 11px;
        text-transform: none;

        i {
            position: relative;
            font-size: 22px;
        }
    }
    .app_participez-content-form-videolink .col {
        margin: 0 !important;
    }
    .filepicker {
        text-transform: uppercase;

    }
    .filepicker, #videolink {
        label {
            padding: 0 !important;
            right: 0;
            text-align: center !important;
            margin-top: -12px !important;

        } 
        background: transparent;
        border: 1px dashed black;
        border-radius: 0;
        
        letter-spacing: .1rem;
        font-family: 'segoeuiBold', Arial;
        margin: 0 auto;
        font-size: 12px;
        width: 60%;
        padding: 0 !important;
        min-height: 60px;

        .dz-message {
            margin: 1rem 0;
        }
        .dz-error-message {
            margin-top: 30px;
            text-transform: none;
            width: 200px;
        }
    }
    .switch {
        position: relative;
        margin-top: 10px;

        &:after {
            content: '';
            position: absolute;
            display: table;
            @include calc(left, '50% - 50px', '');
            bottom: -20px;
            background: $color_main;
            width: 100px;
            height: 7px;
        }
    }
    button {
        font-family: 'seogoeBlack', Arial;
    }
}

/*
Participations 
*/
#app_participations {
    &.app_screen,
    .app_screen { 
        min-height: 110vh;
    }
    .container {
        width: 90%;
    }
    .btn-all {
        font-family: 'segoeuiBlack', Arial;
        font-size: 13px;
        height: auto;
        padding: 10px 20px;
    }
}

.post_list-summary h2,
.modal-content h4 {
    color: black;
    text-transform: none;
    position: relative;
    margin-bottom: 40px;

    &:after {
        content: '';
        background: $color_main;
        width: 190px;
        height: 5px;
        left: -15px;
        bottom: -20px;
        position: absolute;
    }
}
.post_list-summary {
    cursor: pointer;
    overflow: hidden;
    
    a {
        color: black;
    }
    &.card {
        margin: 0;
        padding-bottom: 30px !important;
    }
    .small {
        font-size: 11px;
    }
    .media_type {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 12px;
        padding: 10px;
        color: black;
        background: white;
        z-index: 1;
    }
    
    .card-title {
        color: $gray !important;
    }
    .card-action {
        left: 0;
        right: 0;
        position: absolute;
        bottom: 0;
    }
    .card-content > div > img {
        display: block;
        margin: 0 auto;
    }
    .btn-link {
        font-family: 'segoeuiBold', Arial;
        color: $color_main !important;
        text-decoration: underline;
    }
    p.pseudo {
        font-weight: bold;
    }
    form {
        max-width: 200px;
        display: table;
        width: 100%;
        margin: 0 auto;

        p {
            font-size: 11px !important;
        }
        .btn-vote {
            width: 100%;
            display: table;
            margin: 0 auto;
            border: solid 1px $color_main;
            min-height: 42px;
            position: relative;
            
            div {
                display: table;
                margin: 0 auto;
                
            }            
            span {
                color: $color_main;
                text-transform: uppercase;
                font-weight: bold;
                padding-right: 42px;
            }
            &:after {
                content: '';
                width: 42px;
                height: 40px;
                background-color: $color_main;
                background-image: url(../../img/icons/vote.svg);
                background-size: 15px;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                right: 0;
                top: 0;
            }
            
        }
    }
    .btn-vote:hover {
        &:after {
            background-size: 25px;
        }
    }
    .badge {
        font-weight: bold;
        color: black;
        font-size: 13px;
        text-transform: uppercase;
    }
    .post_list-summary-share {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
    } 
}
.modal {
    h4 {
        text-transform: uppercase;
        color: #ff2a00;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 20px;
        text-align: center;

        &:after {
            display: none;
        }
        /* 
        &:after {
            left: 0;
            right: 0;
        }
        */
    }
    form {
        max-width: 183px;
        width: 183px;
        margin: 0;

        .btn-vote {
            float: left;
        }
        div {
            text-align: right;

            p {
                text-align: left;
            }
        }
    }
    span.badge {
        margin-left: 0;
        float: none;
    }
    .post_list-summary-share {
        position: relative;
        top: auto;
        right: auto;
    }
    .media.application a {
        max-width: 300px;
        margin: 0 auto;
    }
    h3 {
        font-weight: bold;
        color: #ff2a00;
        margin-top: 20px;
    }
    blockquote {
        margin: 25px 15px;
        font-family: "Helvetica Neue", Arial;
        border-left: transparent;
        position: relative;
        background: #f2f6c1;
        padding: 15px;
        font-style: italic;
        font-size: 20px;

        &:before,
        &:after {
            content: '"';
            font-style: italic;
            position: absolute;
            font-size: 50px;
        }
        &:before {
            top: -30px;
            left: -20px;
        }
        &:after {
            bottom: -50px;
            right: 0;
        }
          
        
    }
    
}

#post_details, 
.modal {
    .social_img_fb {
        width: 12px;
        height: 22.5px;
    }
    .social_img_tw {
        width: 30px;
        height: 21px;
    }
    .social_img_send {
        width: 30px;
        height: 25px;
    }
}
#post_details {
    .post_list-summary-share {
        color: white !important;
    }
    .card-action {
        border-top: 0;
    }
}
/* 
Top5
*/
#app_top5 {
    background: white;
    text-align: center;

    & > div {
        cursor: pointer;
        
    }
    h2 {
        color: black;
        font-size: 20px;
        letter-spacing: .6rem;
        margin-top: 16px;
        font-family: 'segoeuiBold', Arial;
        text-align: center;

        img {
            display: block;
        }

        span {
            display: block;
            margin-top: 5px;
            font-size: 40px;
            color: $color_main;
        }
    }
    h3 {
        color: $color_main;
        font-family: 'segoeuiBold', Arial;
        font-size: 35px;
    }
    h4 {
        color: black;
        text-transform: none;
        word-break: break-all;
    }
    .container {
        width: 90%;
    }
    .col {
        padding: 0 1.5rem;
        margin-left: inherit;
        overflow: hidden;
        
       
    }
    .card-image {
        z-index: 0;
        height: 200px;
    }
    
    .text {
        width: 80%;
        margin: -50px auto 0 auto;
        background: white;
        z-index: 1;
    }
}

/*
Post list
*/
#posts__list {
    margin-top: 2.5rem;
   
    .row .col {
        margin-left: inherit;
        margin-bottom: 25px;
        padding: 0 1.5rem;

        &>div {
            height: 100%;
        }
    }
}
.fullyloaded .loader {
    display: none;
}

/*
Page All Posts
*/
#posts {
    &.container {
        width: 90%;
    }
    & > h2 {
        border-bottom: $color_main solid 5px;
        border-top: $color_main solid 5px;
        font-family: "segoeuiBlack", Arial;
        
        letter-spacing: .2rem;
        padding: 5px 15px;
        display: inline-block;
        margin-top: 15px;

        span {
            color: black;
            font-size: 12px;
        }
    }
    .card {
        margin-bottom: 25px;
    }
    #posts__list {
        & > .row > span {
            margin: 0 auto;
            padding: 50px 0;
        }
    }
    
    
}
#app_gagnants {
    .col {
        position: relative;
    }
    /*
    .col:nth-child(2) {
        &:before {
            width: 133px;
            height: 116px;
            background: url(../../img/icons/prix_special.png);
            content: '';
            position: absolute;
            top: -30px;
            right: -20px;
            z-index: 1;
        }
    }
    */
    .rank {
        font-size: 50px;
        font-family: "DCC-SharpDistress", Arial, sans-serif;
        text-align: center;
        color: $color_main;
    }
    .card-image img {
        padding: 25px 50px;
        max-width: 400px;
        margin: 0 auto;
    }
    .post_list-summary.card {
        padding: 0 !important;
    }
    h2.title {
        color: $color_main !important;
        margin: 50px 0 100px 0;
    }
    h2:not(.title) {
        color: black !important;
        padding-top: 22px;
        font-size: 1.5rem;
        text-align:center;
        margin: 0;
        color: $color_main;
        &:after {
            display: none;
        }
    }
    
   
    .col > div {
        display: inline;
    }
}