@import 'elements/mixins.scss';
@import 'elements/_variables.scss';
@import "materialize/materialize.scss";
@import 'elements/utils.scss';
@import 'elements/nav.scss';
@import "elements/form.scss";
@import "elements/pages.scss";
@import 'elements/slider.scss';
@import 'elements/gallery.scss';
@import "elements/anim.scss";
@import "elements/responsive.scss";

/* 
//////////////////////////////////////////////////////////////
HTML / Body 
*/
html, body {
  min-height: 100%; 
  overflow-x: hidden; 
}
body {
  margin: 0;
  padding: 0;
  font-family: "segoeui", Arial, sans-serif;
  
  color: black;
  /* font-family: 'Futura PT', Arial;
  */
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../../img/bg.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
}
a, button {
  cursor: url('../../img/pointer.png') 2 2, auto !important;
}

/*
//////////////////////////////////////////////////////////////
Blocks
//////////////////////////////////////////////////////////////
*/ 
#app_main {
  padding-bottom: $footer_height;
  margin-top: -10px;
}

.app_screen {
  @include calc(min-height, '100vh - 128px', !important);
  // height: calc(100vh - 128px);
 /* margin-bottom: -($menu_height); */
  overflow: visible;
  padding: 0 !important;

}
/* 
//////////////////////////////////////////////////////////////
Fonts 
*/
h1, h2, h3, h4, h5 {
  font-weight: normal;
}
h1, h2 {
  color: $color_main;
  text-transform: uppercase;

}
h1 {
  font-size: 2rem;
  margin-top: 0;
}
.title_bordered {
  color: black;
  display: inline-block;
  font-family: "segoeuiBlack", Arial, sans-serif;
  font-size: 12px;
  letter-spacing: .2rem;
  padding: 0 12px;
  position: relative;

  &:after {
      content: '';
      background: $color_main;
      width: 100%;
      height: 10px;
      left: 0;
      position: absolute;
      bottom: -5px;
      z-index: -1;
  }
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 1.2rem;
}
#app_presentation h2,
h2.title {
  color: rgba(black, .6);
  font-family: "DCC-SharpDistress", Arial, sans-serif;
  font-size: 140px;
  font-weight: normal; 
  margin-top: 6rem;
  text-transform: none;
  line-height: 135px;
}
h2.title {
  color: $color_main !important;
  text-align: center;
  margin-bottom: 90px;
}
.app_usual {
  
  h2 {
    text-transform: none;
    color: black;
  }
  ul li {
    list-style-type: disc;
  }
}
/*
//////////////////////////////////////////////////////////////
Footer
*/
footer.page-footer {
  background: white !important;
  border-top: solid 1px rgba($gray, .5);
  position: fixed;
  min-height: $footer_height;
  height: $footer_height;
  bottom: 0;
  padding-top: 0;
  width: 100%;
  z-index: 997;

  nav {
    background-color: transparent !important;
    @include box-shadow(none);
    
    ul li {
      @include flexbox();
      @include align-items(center);
      min-height: $footer_height;
    }

    & > ul li:not(.right) {
      padding: 0 1rem;
    }

    a {
      color: black;
    }
  }
}

/* 
////////////////////////////////////////////////////////////
Badge
*/
.badge {
  background-color: white;
  color: black;
  font-weight: bold;
}


/* 
////////////////////////////////////////////////////////////
Icon
*/
.material-icons {
  position: absolute;
  right: .5rem;
}


/*
//////////////////////////////////////////////////////////////
Tabs
*/

  ul.tabs {
    display: table;
    min-height: 20rem;
    width: 100%;
    
    .indicator {
      background-color: black;
    }

    .tab a {
      color: black !important;
    }
  }


/*
//////////////////////////////////////////////////////////////
Card
*/

.card-content {
  padding: 12px !important;
  position: relative;
}
.card-title {
  font-size: 14px !important;
//   font-weight: bold !important;
}
.card .card-action {
  padding: 0 10px !important;
  border-top: 0;
}
.card-image {
  
  overflow: hidden;
  position: relative !important;

  img {
    position: relative;
    width: 100%;
  }
  &.type_image {
    width: 100%;
    height: auto;
  }
 
  &.type_application {
    width: 60%;
    height: auto;
    margin: 0 auto;
  }
  /*
  &.type_image {
    img {
        width: 300% !important;
        left: -150% !important;
        min-width: 400px;
        min-height: 300px;     

    }
  }
  */
}

/*
///////////////////////////////////////////////////////////////
Filepond 
*/
.filepond--wrapper {
  label, span {
    color: black !important;
    font-size: 1.2rem;
  }
}

/*
Modal
*/
.modal {
  top: 5% !important;
  max-height: 90% !important;
  width: 95% !important;
}
.modal-close {
  position: absolute;
  content: '';
  font-size: 0;
  top: 10px;
  right: 30px;
  width: 40px;
  height: 30px;
  background-color: transparent !important;
  background-image: url(../../img/icons/close.svg);
  background-size:cover;
  color: black;
}
.modal-overlay {
  background-image: url(../../img/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1 !important;
}
.material-tooltip {
  text-align: left;
  background-color: white;
  color: black;
  border: solid 1px black;

  .backdrop {
    background-color: white;
  }
}