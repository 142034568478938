.ril__outer,
.ril__caption, .ril__toolbar {
    background-color: $color_main;
}
.ril__zoomInButton,
.ril__zoomOutButton {
    display: none;
}
.ril__captionContent {
    margin: 0 auto;
}