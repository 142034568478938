/*
///////////////////////////////////////////////////
Navigation
*/
.navbar-fixed {
  min-height: $menu_height;
  height: $menu_height;
}
nav.nav_main {
  background:white;
  @include box-shadow(0px 2px 10px 0px rgba(168,168,168,0.5));
  margin: 0;
  min-height: $menu_height;
  z-index: 10;
  
  .brand-logo {
    z-index: 1;
    display: block;

    img {

      margin-left: 40px;
      margin-top: 35px;
      width: 75px;
    }
  }
  ul {
    line-height: $menu_height;
  }
 
  .nav-wrapper > ul {
    margin-left: 120px;
    @include calc(width, '100% - 120px', '');

    & > li {
      margin-left: 105px;
      position: relative;

      &:not(:first-child):after {
        position: absolute;
        content: '';
        left: -65px;
        width: 1px;
        top: 0;
        bottom: 0;
        background: $gray;
      }

      &:not(:last-child) {
        a {
          letter-spacing: .4rem;
          position: relative;

          &:before {
            content: '';
            width: 17px;
            height: 14px;
            position: absolute;
            left: -25px;
            @include calc(top, '50% - (14px/2)', '');

            background: url('../../img/icons/arrow_b_r.svg');
          }
        }
        a.active:before,
        a:hover:before {
          background: url('../../img/icons/arrow_r_r.svg');
        }
        
      }
      
    }
    li.right:after {
      left: -15px;
    }
    li.right,
    ul {
      list-style-image: none;
      margin: 0;
    }
    li.right li {
      padding: 0 20px;

      &:not(:last-child) {
        border-right: solid 1px $gray;
      }
      a {
        font-family: 'segoeui', Arial !important;
        
      }
    }  
  }  
}

.btn.sidenav_btn {
  position: fixed;
  top: 0;
  z-index: 1;
}
.sidenav-trigger {
  color: $color_main;
}
nav {
  li a {
    color: black;
    font-family: 'segoeuiBold', Arial;
    font-size: .9rem;
    padding: 0;
    text-transform: uppercase;

    &.active,
    &:hover {
      background: none;
      color: $color_main;
      list-style-image: url('../../img/icons/arrow_r_r.svg');
    }
  }
}
.nav-extended {
  .nav-content {
    min-width: 100%;
    
    a {
      font-size: 12px;
    }
    .site_link {
      font-family: 'segoeuiBold', Arial !important;
    }
    .small {
      font-family: inherit;
      font-weight: normal;
      letter-spacing: inherit;
      text-transform: none;
    }
    ul {
      li{
        border-right: solid 1px $gray;
        padding: 0 20px;
        line-height: $footer_height !important;

        span {
          display: block;
        }
      }
    }
  }
}

.drag-target {
  width: 0 !important;
  height: 0;
}
.nav_mobile {
  button,
  button:hover {
    @include box-shadow(none);
    background-image: url(../../img/icons/burger.svg);
    background-color: transparent !important;
    background-repeat: no-repeat;
    z-index: 1000;
    float: right;
    top: 30px;
    right: 20px;
    position: absolute;
    
  }
  .side-nav {
    float: none;
    position: fixed;
    top: 68px;
    bottom: 0;
    left: 0;
    background: white;   
    z-index: 99;
    margin: 0;
    font-size: 30px;
    line-height: 40px;
    width: 100%;

    li {
      float: none;
      text-align: center;
      margin-top: 20px;
      margin-left: 0 !important;
      text-transform: uppercase;
    }
  }
}